import './SendMails.css'
import React,{useEffect,useContext, useState} from "react";

import Header from "../navbar/header/Header";
import Footer from "../footer/Footer";
import { Link, useNavigate } from 'react-router-dom';
import CoverPreloader from '../../../components/preloader/Coverpreloader';
import { sendEmailByGroup } from '../../../vitals';
import Message from '../../../components/message/Message';
import { userProfileContext } from '../../../components/userprofilecontext/UserContext';
function SendMails(){
    const [email,setEmail] = useState('')
    const [userGroup,setUserGroup] = useState('0')
    const [message, setMessage] = useState('')
    const [emailinput, setemailinput ] = useState(false)
    //const [loading,setLoading] =useState(false)
    const [subject,setSubject] = useState('')
    const [messageOnCard,setMessageOnCard] = useState('')
    const [open,setOpen] = useState(false)
    const{user,loading,setLoading} = useContext(userProfileContext)

	const navigateto = useNavigate()
    if (!user.is_staff){
        navigateto('/dashboard')
    }

    function changeemail(e){
        setEmail(e.target.value)
    }
    function changeMessage(e){
        setMessage(e.target.value)
    }
	function changeUsers(e){
        setemailinput(false)
        let group =e.target.value
        console.log(group)
        setUserGroup(group)
        if(group=='8'){
            setemailinput(true)
        }

    }
    function submitForm(e){
        setLoading(true)
        e.preventDefault()
        async function sendMail(email,subject,message){
            try{
            const response =await sendEmailByGroup({email,subject,message})
            const data = await response.json()
            if(data['message']){
                setMessageOnCard(data['message'])
                setOpen(true)
            }
            }catch(e){

            }finally{
                setLoading(false)

            }
        }

        if  (userGroup=='0'){
            console.log('cant submit')
            setLoading(false)
            return 0;
        }
        if(email && userGroup=='8'){
            console.log('submiting...')
            sendMail(email,subject,message)
            return;
        }
        sendMail(userGroup,subject,message)
      
    }

    function changeSubject(e){
        setSubject(e.target.value)
    }
    function onClose(){
        setOpen(false)
    }
    return(
    < >
		<Header/>
        <br/>
        <div className='container'>
        <form className="justify-content-center text-center updatecard"  onSubmit={submitForm}>

		<div className="form-group form-group text-center ">
            <select className="form-control" name='users' onChange={changeUsers} style={{width:'80%', marginLeft:'30px'}}>
            <option value='0'>Select User group</option>
            <option value='1'>New Users</option>
            <option value='2'>Old users GT 7days</option>
            <option value='3'>Users without Refs</option>            
            <option value='4'>Users without Deposit</option>
            <option value='5'>Users With Wallet LT #200</option>
            <option value='6'>Users Without txn in 7days</option>
            <option value='7'>All Users</option>
            <option value='8'>A single users</option>
            </select>
		</div>
        {emailinput? <div className="input">
        <input id ='emailinp' type="email"  name="email" value={email} onChange={changeemail}
         className="form-control" placeholder="Enter a User email" style={{width:'80%'}}/>
        </div> :''}
        <div className="input">
        <input id ='emailinp' type="text"  name="subject" value={subject} onChange={changeSubject}
         className="form-control" placeholder="Enter EMail Subject" style={{width:'80%'}}/>
        </div>
        {loading? <CoverPreloader loading={loading}/>:''}
		<div className="form-group text-center " >
            <textarea name='message' style ={{width:'90%', height:'400px'}} onChange={changeMessage} required/>
		</div>
        <div className="text-center">
        <button  className ="btn btn-rounded btn-primary text-center"  type="submit">
            <i className ="fa fa-up"></i>Send Message</button>

        </div>
        </form>
        <Message message={message} isOpen={open} onClose={onClose}/>
        </div>
		<Link to="https://wa.me/message/DHCVWD7K25CWB1" className="whatsapbtn">
        Chat us(we reply in less than 1Min)</Link>
		<Footer/>
	</>
    );
}

export default SendMails


