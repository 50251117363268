import React,{useState,useEffect,useContext} from "react";
import Account from "../../../components/account/account";
import Header from '../navbar/header/Header'
import Footer from "../footer/Footer";
import '../../../components/data/Data.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PassCode from "../../../components/passcode/Passcode";
import { SetPassCode } from "../../../components/passcode/Passcode";
import Modal from 'react-modal';
import { fetchUserProfile, setTransactionPin,fetchBanks } from "../../../vitals";
import { useNavigate } from "react-router-dom";
import CoverPreloader from "../../../components/preloader/Coverpreloader";
import Message from "../../../components/message/Message";
import './Deposit.css'
import { withDrawFunds } from "../../../vitals";
export default function Withdraw(){
    const [loading,setLoading] = useState(false)
    const [isPasCodeOpen, setPassCodeOpen] = useState(false);
    const [isTxnPinOpen,setIsTxnPinOpen] = useState(false)
    const[accounNumber,setAccountNumber] = useState('')
    const [bankName,setBankName] = useState('')
    const[amount,setAmount]=useState('')
    const [messageIsOpen,setMessageIsOpen] = useState(false)
    const[message,setMessage] = useState('')
    const [IsValid,setIsValid] = useState(false)
    const [user,setUser] = useState([])
    const [banksList,setbanksLinsts] = useState('')
const navigateto = useNavigate()
    

let popularBanks = [

  {'id': 254, 'code': '090267', 'name': 'Kuda'},
  {'id': 1435, 'code': '100004', 'name': 'Opay'},
  {'id': 5, 'code': '011', 'name': 'First Bank PLC'}, 
  {'id': 2, 'code': '023', 'name': 'Citi Bank'}, 
  {'id': 186, 'code': '030', 'name': 'Heritage Bank'}, 
  {'id': 14, 'code': '032', 'name': 'Union Bank PLC'},
  {'id': 2, 'code': '023', 'name': 'Citi Bank'}, 
  {'id': 186, 'code': '030', 'name': 'Heritage Bank'}, 
  {'id': 14, 'code': '032', 'name': 'Union Bank PLC'}, 
  {'id': 13, 'code': '033', 'name': 'United Bank for Africa'}, 
  {'id': 15, 'code': '035', 'name': 'Wema Bank PLC'}, 
  {'id': 1, 'code': '044', 'name': 'Access Bank'}, 
  {'id': 4, 'code': '050', 'name': 'EcoBank PLC'},
  {'id': 16, 'code': '057', 'name': 'Zenith bank PLC'}, 
  {'id': 8, 'code': '058', 'name': 'Guaranty Trust Bank'}, 
  {'id': 826, 'code': '060001', 'name': 'Coronation Merchant Bank'},
];
  useEffect(()=>{
    const fetchProfile = async () => {
      try {
        setLoading(true);
          const userResp = await fetchUserProfile({navigateto});
          const userProfile = await userResp.json();
          setUser(userProfile);
  
      } catch (error) {
        console.log(error)
      } finally{
        setLoading(false);
      }
    };
  
    fetchProfile();
   

  },[] )

    function handleAmountChange(e){
        setAmount(e.target.value)

    }
    function handleAccountChange(e){
        setAccountNumber(e.target.value)

    }
    function handleBankChange(e){
        setBankName(e.target.value)

    }

    function submitForm(e){
        e.preventDefault()
        //console.log(user.txn_pin)
        if (!user.txn_pin){
          setIsTxnPinOpen(true)
  
        }else{
          setPassCodeOpen(true)
  
        }
  
      }
  
      function closeTxnPin(){
        setIsTxnPinOpen(false)
      }
      
     function closePassCode(){
      setPassCodeOpen(false)
     };
     function closeMessage(){
        setMessageIsOpen(false)  
        
        setTimeout(()=>{
        //window.location.reload()
        },1000)
        }

    function continueToWithdraw(){
        setPassCodeOpen(false)
        setLoading(true)
        async function withdraw(){
            let data;
            try{
                const response = await withDrawFunds({navigateto,amount,accounNumber,bankName})
                 data = await response.json()
                
            }catch(e){
    
            }finally{
                setLoading(false)
              
            }
            setMessage(data['message'])
            setTimeout(()=>{
                setMessage('')
            },2000)
    
        }
    withdraw()
    }
    const BankSelectOptions = ({banks}) => {
      return (
        <select>
        <option key ='null' value="null">Select Bank</option>

          {banks.map(bank => (
            <option key={bank.id} value={bank.code}>
              {bank.name}
            </option>
          ))}
        </select>
      );
    };
    
        Modal.setAppElement('#root'); 
    return (
        <>
        <Header></Header>
        <Account/>
        <div className="contents valid-form">
        <form method="post" className="input-group" onSubmit={submitForm}>
            <h1>Withdraw to Bank</h1>
            {message? <h1 style={{color:'gold', marginTop:'30px'}}>{message}</h1>:<>
            <BankSelectOptions banks={popularBanks} value={bankName} onChange={handleBankChange} style={{width:'80%'}}/>
            <input type="text" name="accountNumber"   autoComplete="off" className="spa" required style={{width:'80%'}}
                    placeholder="Account Number" value={accounNumber} onChange={handleAccountChange} onPaste={handleAccountChange}/>
            <input type="number"  className="spa" required
                placeholder="Amount" value={amount} onChange={handleAmountChange} style={{width:'80%'}} />
</>}
{loading && <CoverPreloader loading={loading}isok ={false}/>}

<button  className='btn-primary databtn'  type="btn">Proceed</button>


               
            </form>
            
            
        </div>
        <Message
          isOpen={messageIsOpen}
          message={message}
          onClose={closeMessage}
          />
        
        <SetPassCode
        isOpen={isTxnPinOpen}
        onClose={closeTxnPin}
        />
        <PassCode
        isOpen={isPasCodeOpen}
        onClose={closePassCode}
        onContinue={continueToWithdraw}
        user ={user}
          />

        <Footer/>
        </>
    )
}