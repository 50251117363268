import { useMutation } from "@tanstack/react-query";
import { postMethod } from ".";
import { APIs } from "./api_urls";

export const useLoginMutation=()=>{
    const loginMutation = useMutation({
        mutationFn: (payload)=>{
            
        }
    })
    return loginMutation
}

//register user
export const useRegisterMutation=()=>{
    const registerMutation = useMutation({
        mutationFn: (payload)=>{
            return postMethod(APIs.register, payload)
        }
    })

    return registerMutation;
}