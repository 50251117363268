import React, {useState}from 'react'
import styles from '../Navbars.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faRefresh} from '@fortawesome/free-solid-svg-icons';
import Aside from '../aside/Aside';
import { UserProfileProvider } from '../../../../components/userprofilecontext/UserContext';
export default function Navbar(props){
    const[isToggled,setIsToggled] = useState(false)

    function toggleNavBar(){
        setIsToggled(!isToggled)
        setTimeout(()=>
        {        setIsToggled(false)
        },7000)
    }
    return(
        <UserProfileProvider>
        <header>
            


	<div className={`${styles['wrapper']} bg-brand-bg `}>
    {/* <button   onClick='' style={{color:'brown',backgroundColor:'none'}}><FontAwesomeIcon icon={faRefresh} style={{backgroundColor:'none'}}/></button> */}

		<Aside isToggled={isToggled} user = {props.user}/>
     <div className={`${styles['col']} ${styles['topright']}`}> 
		<FontAwesomeIcon icon={faBars} className=  {`${styles['hamburger']} ${styles['align-self-center']}`} onClick={toggleNavBar}/>
        </div>
      
</div>
</header>
</UserProfileProvider>


    )
}