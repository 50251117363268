import React, { useEffect,useState } from 'react';
import './Payment.css';
/* global FlutterwaveCheckout */
import image_logo from '../../assets/images/paysit.png'
import { verifyPayment } from '../../vitals';
import Message from '../message/Message';
import { useNavigate } from 'react-router-dom';
import CoverPreloader from '../preloader/Coverpreloader';

export default function Payment({amount,transaction}) {
    const [message, setMessage] = useState('')
    const [messageIsOpen,setMessageIsOpen] = useState(false)
    const [loading,setLoading] = useState(false)
    const navigateto = useNavigate()

    const amountEnter = new Intl.NumberFormat('en-NG', {style: 'currency',currency: 'NGN',}).format(amount);
        // Calculate 1.4% of the amount
        const fee = (amount * 1.4) / 100;
      
        // Calculate the total amount including the fee
        const total = +amount + fee;
    const amountToPay = new Intl.NumberFormat('en-NG', {style: 'currency',currency: 'NGN',}).format(total);
    const dateObject = new Date(transaction.date_added);

    // Format the date using toLocaleString or other formatting options
    const formattedDate = dateObject.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
  useEffect(() => {

    const script = document.createElement('script');
    script.src = 'https://checkout.flutterwave.com/v3.js';
    script.async = true;
    document.head.appendChild(script);

    // Cleanup the script tag after component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  function makePayment() {
    FlutterwaveCheckout({
      public_key: transaction['Pk'],
      tx_ref: transaction.payment_id,
      amount: transaction.amount,
      currency: "NGN",
      payment_options: "banktransfer,bank,ussd,card",
      meta: {
        source: "PaysIt Webs",
        consumer_mac: "92a3-912ba-1192a",
      },
      customer: {
        email: transaction['user'].email,
        phone_number: transaction['user'].phone_number,
        name: `${transaction['user'].first_name} ${transaction['user'].last_name}`,
      },
      customizations: {
        title: "PaysIt",
        description: "Wallet Funding",
        logo:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAgCAMAAADZqYNOAAAAWlBMVEVHcEynzla31Vpim15VjlZVk1aq0Eup0Eiq0UpQkVE/d0RCf0VGi0ZAdUVHhUlMjU1EhUZGkUdHlEdPm0xKmUdMiE9On0dOnEmjzUxNmkpIh0pIi0lEfkhIh0vgQN7kAAAAHnRSTlMAJ1UUSC23/+dj////8diT////yP93/+ha17Ln6sLxpFZ7AAABP0lEQVR4AX3OgW6DIBCA4euAbcgVjivoCvL+r7lDamoStl+VkC9HALh9HAGA0j2j4NLnV+/7BmAXh4h3H8xUcSGM8otBTbQLMy7IDzVRjo44xQXT4Ktm5pTIcWKHKU+105oItzF8+zmSjbFSeK5ILF+GWeqxJpTxAtPMtsrR49rTYQn9H/pM0vqcq2Vm4fnJpsbIUoBe8Hvdq/etFalVjChvjPrQQq4/EklIOPIwFB2S6IheoTkVXXRuWdwlyvDSyBSJlqNTLbyVKbnlUtFw0YSM91d7ywakiybcbqBAKTgzF11TNa1meCPrpzk1rVvGVk0oxtogi0aNj6Gdt1x9C7X4EnZNzZBGeyhGZtG7hbCHe/FOF2ed3tvQzpsNClTO2exFFpWVHrPUeTPwqmR4JyqMVXRWccL/qDD9o9JcfwHKuR6y9H/ICwAAAABJRU5ErkJggg==',
        logo_url:image_logo,
      },
      callback: async function (data){
        setLoading(true)
        data['payment_id'] =data['tx_ref']
        try{
          const response = await verifyPayment({navigateto,data})
          const resData = await response.json()
          if(resData['message']){
           setMessage(resData['message'])
           setMessageIsOpen(true)}
              
        }catch(e){
          console.log(e)
        }finally{
          setLoading(false)
          //navigateto('/dashboard')
        }
      },
      onclose: async function () {
       
        let data = transaction
            data['status'] = 'canceled'
         const response = await verifyPayment({navigateto,data})
         //const resData = await response.json()
      
         

      }
    });
  }
function closeMessage(){
setMessageIsOpen(false)
setTimeout(()=>{
    navigateto('/dashboard')
 },100)   
}
  return (
    <>
      <form className='paymentform cont'>
            <div className="card"> 
                            <div className="col s12 center-align"> 
                                <h6> PLEASE CONFIRM TRANSACTION DETAILS BELOW </h6><br/>
                            </div>
                            
                                <div className='row'>
                                <div className="col s4 ">
                                    <p >
                                        <span className="light-blue-text">User</span><br/>
                                        <b className='txt1'>{transaction['user'].first_name} {transaction['user'].last_name}</b><br/>
                                        <b>{transaction['user'].email}</b><br/> 
                                    </p>
                                    </div> 
                                    <div className="col s4">
                                        <p>
                                            <span className="light-blue-text">Date</span><br/>
                                            <b>{formattedDate}</b><br/>
                                        </p>
                                    </div> 
                                    {loading? <CoverPreloader loading={loading}/>:''}

                                    <div className="col s4">
                                        <p>
                                           <span className="light-blue-text">Status</span><br/>
                                            <b>Pending</b><br/>
                                            

                                        </p>
                                    </div>
                                    </div>
                               
                                <div className="row container">
                                    <div className="col s12">
                                        <table className="responsive-table ">
                                            <thead>
                                                <tr>
                                                    <th className="left-on-sm-only right-align">id</th>
                                                    <th className="left-on-sm-only right-align">Services</th>
                                                    <th className="left-on-sm-only right-align">Amount</th>
                                                    <th className="left-on-sm-only center-align">Fee</th> 
                                                </tr><br/>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className=""> {transaction.payment_id}</td>
                                                    <td className="left-on-sm-only right-align">Wallet Funding</td>
                                                    <td className="left-on-sm-only right-align">{amountEnter}</td>
                                                     <td className="left-on-sm-only right-align">₦0.0 </td>   
                                                     {/* <td className="text-success">{amountToPay}</td>                    */}
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                        
                                                                                    
                                                                             
                                    

                  
                 </div>
                  
                  
                  
                  
        {/* <div>Your order is ₦2,500</div> */}
      
        <button type="button" className='border-block btn-primary' style={{width:'40%',fontWeight:'700',color:'white',marginTop:'40px'}} id="start-payment-button" onClick={makePayment}>
          Proceed to Pay
        </button>
      </form>
      <Message
message = {message}
onClose = {closeMessage}
isOpen = {messageIsOpen}
/>
    </>
    
  )

}
