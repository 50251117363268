import React from "react";

export default function AlertCard({ qty=null, service=null, number=null, message=null }) {
  return (
    <div className="col content tvform">
      {qty? <>
      <h3 className="text-center bg-success rounded-btn">{message}</h3>
      <div className="alert alert-info">
        <div className="text-center">
          <b>{qty}</b> of {service} successfully gifted to <b>{number}</b> <br />
          <br />
          <i className="fa fa-check-circle"></i>
          <small className="suport" style={{ fontSize: "14px", color: "green" }}>
            Completed
          </small>
        </div>

        <br />
        {/* <small className="suport" style={{ fontSize: "7px", color: "brown", paddingTop: "10px" }}>
          All failed Transactions will be refunded, always check your transaction status by refreshing your dashboard
        </small> */}
      </div></>:<><h3 className="text-center bg-warning rounded-btn">{message}</h3>
</>}
    </div>
  );
}
