import React from 'react'
import { Input as AntInput, ConfigProvider } from "antd"

const PasswordInput = ({className, ...rest}) => {
  return (
    <>
    <ConfigProvider theme={{
            components: {
                Input: {
                    hoverBorderColor: "rgba(31, 94, 180, 0.22);",
                    activeBorderColor: "rgba(31, 94, 180, 0.22);",
                    activeShadow: "0",
                }
            }
        }}>

         <AntInput.Password
               {...rest}
                className={`h-[45px] text-[14px] ${className}`}
              />

        </ConfigProvider>
    </>
  )
}


export default PasswordInput;