import React,{useState,useEffect,useContext} from "react";
import nameToLogo, { validatePhoneNumber,fetchData, buyData } from "../../vitals";
import Account from "../account/account";
import Header from '../../pages/dashboard/navbar/header/Header'
import Footer from "../../pages/dashboard/footer/Footer";
import './Data.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PassCode from "../passcode/Passcode";
import {SetPassCode} from "../passcode/Passcode";
import Modal from 'react-modal';
import { userProfileContext,UserProfileProvider } from "../userprofilecontext/UserContext";
import { fetchUserProfile,} from "../../vitals";
import { useNavigate } from "react-router-dom";
import CoverPreloader from "../preloader/Coverpreloader";
import Message from "../message/Message";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { buyAirtime } from "../../vitals";
import AlertCard from "../alertcard/AlertCard";
import DataCard from "../datacard/DataCard";
import DataConfirmation from "./DataConfirmation";
import { formartToCurrency } from "../../vitals";
export default function Data(props){
    const [phoneNumber, setPhoneNumber] = useState('')
    const [amount, setAmount] = useState('')
    const[network,setNetwork] = useState('')
    const [numberIsValid,setNumberIsValid] = useState(false)
    const[availableData,setAvailableData] = useState([])
    const[selectedData,setSelectedData] = useState([])
    const [selectedPlan, setSelectedPlan] = useState("0"); // Default to "Select Plan"
    const [isPasCodeOpen, setPassCodeOpen] = useState(false);
    const [isTxnPinOpen,setIsTxnPinOpen] = useState(false)
    const[loading,setLoading] = useState(false)
    const[user,setUser] =useState({})
    const [message, setMessage] = useState('')
    const [messageIsOpen,setMessageIsOpen] = useState(false)
    const [optionSelected, setOptionSelected] = useState('')
    const [airtimeOptions, setAirtimeOptions] = useState('')
    const [transaction,setTransaction] = useState('')
    const [confirmation,setConfirmation] = useState(false)
    const [dataToConfirm,setDataToConfirm] = useState('')
    const [selectedAmount,setSelectedAmount] = useState('')
    const [discountedAmount,setDiscountedAmount] = useState(0)
    const navigateto = useNavigate()
    const listAmount = [100,200,300,400,500,1000]

  const location = useLocation();
  const urlservice = new URLSearchParams(location.search).get("service",'');
    function handleChange(e){
        setPhoneNumber(e.target.value)
        const phoneNo = e.target.value
        if(phoneNo.length <=7){
            setSelectedData([])
            setNetwork('Unknown')
            setNumberIsValid(false)
          }
        if (phoneNo.length >=8){
            let {isValid,network}= validatePhoneNumber(phoneNo)
            setNetwork(network)
            setNumberIsValid(isValid)
            if(isValid && urlservice ==='data'){
              const filteredData = availableData.filter(item => item.name.toLowerCase().includes(network.toLowerCase()));
              if (filteredData.length > 0) {
                const sortedData = filteredData.sort((a, b) => {
                    // Extract numerical values and units from displayName
                    const [valueA, unitA] = a.displayName.match(/\d+|\D+/g);
                    const [valueB, unitB] = b.displayName.match(/\d+|\D+/g);
            
                // Convert MB to a larger unit for consistent sorting
                const unitToNumber = unit => (unit.toLowerCase() === 'mb' ? 1 : 0);
        
                // Compare values and units in ascending order
                    return (
                        parseFloat(valueA) - parseFloat(valueB) ||
                        unitToNumber(unitA) - unitToNumber(unitB)
                        );
              });
        
                setSelectedData(sortedData);
              }
              else{
                    setSelectedData([]);
                  }

            }else if(isValid&&urlservice==='airtime'){
              const amountOptions = listAmount.map((amount)=>(
              <option className="airtimeOptions" key ={amount} network={network} value = {amount} amount={amount} >{formartToCurrency(amount)}</option>
              ))
            setAirtimeOptions(amountOptions)
            }else{
                  if ('vibrate' in navigator) {
                    navigator.vibrate([200, 100, 200]);
                  }  

        }
  
             
      }  

    }

  function applyDiscount(amount){
    return +amount-+amount*0.01
  }

  function handleAmountChange(e){
        if(urlservice ==='airtime'){
          const Amount = e.target.value;
          //console.log(Amount)
          setAmount(Amount);
          setDiscountedAmount(applyDiscount(Amount))
          setSelectedPlan(e.target.value)
          let amountdata = {
            key:Amount,
            amount:Amount,
            value:Amount,
            network:network,
          }
          setOptionSelected(amountdata)
          //console.log(amountOptions)
        }
       
        
    }
    useEffect(() => {
      const fetchDataAndUserProfile = async () => {
        try {
          setLoading(true);
          let endppoints = [fetchData({navigateto}),
            fetchUserProfile({navigateto})]
          if(urlservice==='airtime'){
            const response = await fetchUserProfile({navigateto})
            const userData = await response.json();
            setUser(userData);
          }else if(urlservice==='data'){ 
            const[dataResp,userResp] = await Promise.all(endppoints);
            const userProfile = await userResp.json();
            const Data = await dataResp.json();
            setUser(userProfile);
            setAvailableData(Data);
          }
          else{
            return;
          }
        

        } catch (error) {
          console.error(error);
        }finally{
          setLoading(false);
        }
      };
    
      fetchDataAndUserProfile();
    }, [urlservice]);
    

    async function submitForm(e,data=null){
      e.preventDefault()
      //console.log(user.txn_pin)
      // if (!user.txn_pin){
      //   setIsTxnPinOpen(true)

      // }else{
      //   setPassCodeOpen(true)

      // }
      setLoading(true)
      if(urlservice ==='data'){
        let Plan =data.value
        let Selected=data
        let phone =data.phone
        let cost =data.amount
        try{
          const buyResp =  await buyData({navigateto,Plan,cost,phone,Selected})
          //console.log(selectedPlan)
          const data = await buyResp.json()
          if(data['message']){
            setMessage(data['message'])
            setMessageIsOpen(true)
            setTransaction(data)
          }else{
            setMessage('An Error Occur!!')
            setMessageIsOpen(true)
          }
          
        }catch(e){
          setMessage('An Error Occur!!')
          setMessageIsOpen(true)
        }finally{
          setLoading(false)
          setConfirmation(false)
        }
      }

      else if(urlservice ==='airtime'){

        try{
          //console.log(discountedAmount,phoneNumber,network)
          const buyResp =  await buyAirtime({navigateto,discountedAmount,phoneNumber,network})
          //console.log(selectedPlan)
          const data = await buyResp.json()
          
            if(data['message']){
              setMessage(data['message'])
              setMessageIsOpen(true)
              setTransaction(data)
              //console.log(data)
            }else{
              setMessage('An Error Occur')
              setMessageIsOpen(true) 
            }
        
          
        }catch(e){
          //setMessage('An Error Occur!!')
        }finally{
          setLoading(false)

        }
      }

    }

    function closeTxnPin(){
      setIsTxnPinOpen(false)
    }
    
   function closePassCode(){
    setPassCodeOpen(false)
   };
   //user to set pin

  //  function userSetPassCode(){
  //  };
function closeMessage(){
setMessageIsOpen(false)  

setTimeout(()=>{
setPhoneNumber('')

},1000)
}
const handleContinue = async() => {     
      //console.log('Continue with transaction logic');
  
      closePassCode();
      //check if account balance is okay
      //run the data purchase
      

    };

    const DataLogo=({name})=>{
      return(
        <div className="datalogo">
          <img src={nameToLogo(`${name}`)} alt={name}/>
        </div>
      )
    }
    let lst = ['mtn','airtel','glo','etisalat']
    const LogoList = (
      <div className="row">
        {lst.map((logo) => (
          <div className="col" key={logo}>
            <DataLogo name={logo} />
          </div>
        ))}
      </div>
    );

function onSelect(data){
  data['phone'] =phoneNumber
  data['displayName'] = data.dname
  setDataToConfirm(data)
  setConfirmation(true)
  }
  function closeConf(){
    //submitForm(data)
    setConfirmation(false)
  }
  function submitAfterConf(e,data){
    submitForm(e,data)
  }
    Modal.setAppElement('#root'); 
    return (
        <>
        <UserProfileProvider>

        <Header></Header>
        <Account/>
        {loading && <CoverPreloader loading={loading}isok ={false}/>}
       {!transaction? <div className="valid-form">
            {/* <p className="desc">Experience seamless <b>{service}</b> purchase with our lightning-fast service! At the click of a button, 
                you can swiftly acquire the <b>{service}</b>  you need without any delays</p> */}
      <form method="POST" autoComplete="off" className="text-center"  onSubmit={submitForm}>
											<div className="form-number">
                      {network? <div className="top-logos"><DataLogo name={network}/></div>:''}

                      <span className="check" >
                    {numberIsValid ? (
                                            <span className="bg-primary"><FontAwesomeIcon icon={faCheck}/>{network}</span>

                    ):(<b className="notvalid">x{network}</b>)}
                    </span>
                    
              <input type="number" name="email"
								placeholder="PhoneNumber"  className="number-input" required value={phoneNumber} 
                onChange={handleChange} onPaste={handleChange} />
                
						</div>
            <div className="fixe-in">
            { urlservice==='data'? (
            selectedData && selectedData.length > 0 ? <>
            {selectedData.map((plan) => (
                        <DataCard qty ={plan.qty}key={plan.value} 
                        value={plan.value} amount={plan.price} service ={plan.service} 
                        name ={plan.name} dname ={plan.displayName} onSelect = {onSelect}>
                        </DataCard>
                        ))}</>
                        :(
                          phoneNumber && !numberIsValid ? <>Enter a valid Phone number</>:
                          phoneNumber && numberIsValid ? <>0 Data plans Available for selected network(Pls wait as we work on it)</>:'')
                         
                        ):''}
            </div>
            {dataToConfirm? <DataConfirmation data ={dataToConfirm} 
              openConf={confirmation} closeConf={closeConf} submit ={submitAfterConf} loading={loading}/>:''}

            {urlservice === 'airtime'? <>
             <div>
             { listAmount.map((item) => (
               <button type="button"
                 className={`airtimeOptions ${selectedAmount === item ? 'selected' : ''}`}
                 key={item} value={item}
                 onClick={handleAmountChange}
               >
                 { formartToCurrency(item)}
               </button>
             ))}
           </div>
       
             <div className="row">
              <div className="col">
              <div className="form-number">
              <input type="number" name="amount"
								placeholder={formartToCurrency(amount)}  className="number-input" required value={amount} 
                onChange={handleAmountChange} />
                </div>
              </div>
              
              <div className="form-number">
              <label><b style={{fontSize:'8px',color:'brown'}}>1% Discount</b></label>
              <input type="number" name="cost"
								placeholder={formartToCurrency(discountedAmount)}  className="number-input" required 
               disabled/>
                </div>
    
             </div>
                {phoneNumber && amount ? <button type="submit" className='btn-primary databtn' >Proceed</button>:
                <button type="button" className='btn-primary databtn disabled' >Proceed</button>
                }
                </>
                :''}
            </form>
            {LogoList}
           {/* <form method="post" className="input-group" onSubmit={submitForm}>
            
                
                    

             <label>Select Plan</label>
            <select name="data" className="input-group" onChange={handleAmountChange} value={selectedPlan} required>
                    <option value="0" amount="0">Select Plan</option>
                    {service ==='data'?(selectedData && selectedData.length > 0 ? (
                      //console.log(selectedData);
                      selectedData.map((plan) => (
                        <option key={plan.value} value={plan.value} amount={plan.price} service ={plan.service} name ={plan.name} dname ={plan.displayName}>
                            {`${plan.displayName} ${plan.name}`}
                        </option>
                        ))
                    ):(
                       !numberIsValid ? (<option>Enter a valid Phone number</option>):
                        (<option>0 Data plans Available for selected Network(Pls wait as we work on it)</option>)
                      
                    )):(<>
                      {airtimeOptions}
                      </>
                      )}
                </select>


            <label>Amount {service ==='airtime'? 
            <b style={{fontSize:'8px',color:'brown'}}>1% Discount</b>:''}</label>
            {loading && <CoverPreloader loading={loading} isok={false}/>}
            <input type="text"  className="input-group"
                placeholder="Amount" value={`₦${amount}`} onChange={handleAmountChange} disabled/>

                        {numberIsValid?(
                                        <button  className='btn-primary databtn'  type="submit">Proceed</button>

                        ):(
                                        <button type="submit" className='btn-primary databtn disabled' >Proceed</button>

                        )}
               
            </form> */}
        </div>:<>
        
        
  {transaction.number? <AlertCard qty ={transaction.qty} 
    service={urlservice} number ={transaction.number} message ={transaction['message']}/>:
    
    transaction['message']? <AlertCard message={transaction['message']}/>:''}
        </>}
        <Message
          isOpen={messageIsOpen}
          message={message}
          onClose={closeMessage}
          />
        
        <SetPassCode
        isOpen={isTxnPinOpen}
        onClose={closeTxnPin}
        />
        <PassCode
        isOpen={isPasCodeOpen}
        onClose={closePassCode}
        onContinue={handleContinue}
        user ={user}
          />
    </UserProfileProvider>
<div className="ft">
<Footer/>

</div>
        </>
    )
}
