import React from "react";
import Modal from 'react-modal';
import nameToLogo from "../../vitals";
import { formartToCurrency } from "../../vitals";
import CoverPreloader from "../preloader/Coverpreloader";
export default function DataConfirmation({data,closeConf,openConf,submit,loading}){
    const currentDate = new Date();
const dateString = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
    return (

        <Modal
        isOpen={openConf}
        onRequestClose={closeConf}
        className=""
        overlayClassName=""
      >
    <div className="col content tvform">

       
            <h3 className="text-center bg-success rounded-btn">Confirm Transaction</h3>
        
            <img src={nameToLogo(data.service)} alt="logo" style={{width:'60px',marginLeft:'30%',height:'60px'}}/>
        <div className="alert">
      <span onClick={closeConf} className='closex'>x</span>

<table class="table">
<tbody><tr></tr>

   <tr><td>Service</td> 
   <td>
     {data.service} </td>
</tr><br/>
{loading? <CoverPreloader loading={loading}/>:''}

<tr>
   <td>Data amount
   </td> 
   
   <td>
     {data.qty} </td>
</tr>


<tr>
   <td>
        Number   </td>
     <td>
     {data.phone}    &nbsp;</td>
</tr>
<tr>
   <td>
      Amount   </td>
     <td> {formartToCurrency(data.amount)} 
     
        </td>
</tr><br/>

<tr>
   <td>
     Fee   </td>
     <td>
     {formartToCurrency(0)} </td>
</tr><br/>

<tr>
   <td>
    Amount Charged	</td>
    <td>{formartToCurrency(data.amount)}   </td>
</tr>
<br/>
<tr>
   <td>
       Validity   </td>
   <td>{data.dname}</td>
</tr><br/>
<tr>
   <td>Status</td>
    <td className="bg-warning">Pending</td>
</tr>
<br/>
<tr>
   <td>Date   </td>
    <td>{dateString}</td>
</tr>



</tbody>

</table>

      </div>
<div className="text-center">
<button onClick={(e)=>submit(e,data)} id="btnelectric" className ="btn btn-rounded btn-primary text-center" ><i className ="fa fa-arrow-up"></i> Buy </button>

</div>
    </div>
        </Modal>
    )
}