import axios from "axios";
import { getCsrfToken } from "../vitals";

const server_url = process.env.REACT_APP_SERVER_URL;

const csrfToken = getCsrfToken();

//regular post method
export const postMethod = async (path, payload, token) => {

  const csrfToken = getCsrfToken();

  console.log(csrfToken);
  const headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": csrfToken !== null ? csrfToken : 'null',
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }




  let response = await axios.post(server_url + path, payload, { headers });

  console.log('Request Headers:', response.config.headers);

  return response;
};

// export const postMethod = async(path, payload, token)=>{

//     const headers= {
//         'Content-Type': 'application/json',
//         'X-CSRFToken': csrfToken,

//         'Authorization': `Bearer ${token}`, }
//     let response = await axios.post(server_url+path, payload, {headers})
//     return response;
// }

//post method with authorisation token
export const postMethodWithCSRF = async (path, payload) => {
  const headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": csrfToken,
  };

  let response = await axios.post(server_url + path, payload, { headers });

  return response;
};

//post request method with header
export const PostMethodWithAuthToken = async (path, token, payload) => {
  const headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": csrfToken,

    Authorization: `Bearer ${token}`,
  };
  let response = await axios.post(server_url + path, payload, { headers });
  return response;
};

//get request method with header
export const GetMethod = async (path, token, queryParams) => {
  // console.log(queryParams);

  const headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": csrfToken,
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const response = await axios.get(server_url + path, {
    headers: headers,
    params: queryParams,
  });
  return response;
};
