import React from "react";
import Modal from 'react-modal';
import nameToLogo from '../../../vitals';
import {formartToCurrency} from '../../../vitals';
import { DateTimeComponent } from "./Transactions";
export default function Transaction({transaction,onClose,isOpen}){
    return (

        <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className=""
        overlayClassName=""
      >
    <div className="col content tvform">

        {transaction.completed?<>
            <h3 className="text-center bg-success rounded-btn">Transaction was Successful!</h3>
        </>:transaction.refunded ? <>
            <h3 className="text-center bg-danger rounded-btn">Transaction got Refunded!</h3>
        
            </>:<>
            <h3 className="text-center bg-warning rounded-btn">Transaction is Pending!</h3>

            </>}
            <img src={nameToLogo(transaction.service)} alt="logo" style={{width:'60px',marginLeft:'30%',height:'60px'}}/>
        <div className="alert">
      <span onClick={onClose} className='closex'>x</span>

   
<table class="table">
<tbody><tr></tr>

   <tr><td>Service Name</td> 
   <td>
     {transaction.service} </td>
</tr><br/>


<tr>
   <td>Service
   </td> 
   
   <td>
     {transaction.item}   </td>
</tr>

<tr>
   <td>
        Number   </td>
     <td>
     {transaction.number}   </td>
</tr>
<tr>
   <td>
      Amount   </td>
     <td>{transaction.item.toLowerCase().includes('airtime')? <>{formartToCurrency(transaction.qty)} </>
     : transaction.item.toLowerCase().includes('deposit')? <>{formartToCurrency(transaction.cost)} </> : <>{transaction.qty}</>
     }
        </td>
</tr><br/>

<tr>
   <td>
     Fee   </td>
     <td>
      ₦0.00 </td>
</tr><br/>

<tr>
   <td>
    Amount Charged	</td>
    <td>{formartToCurrency(transaction.cost)}   </td>
</tr>
<br/>
<tr>
   <td>
       Transaction ID   </td>
   <td>{transaction.txn_id}</td>
</tr>
<tr>
   <td>Status</td>
    {transaction.completed? <td className="bg-success">Successful   </td>:transaction.refunded?<td className="bg-danger">Refunded   </td>:<td className="bg-warning">Pending   </td>}
</tr>
<br/>
<tr>
   <td>Date   </td>
    <td><DateTimeComponent datetime={transaction.date_added}/> </td>
</tr>


 

</tbody>

</table>

        <br />
    
      </div>
<div className="text-center">
<button onClick={onClose} id="btnelectric" className ="btn btn-rounded btn-primary text-center" ><i className ="fa fa-arrow-down"></i> Close </button>

</div>
    </div>
        </Modal>
    )
}