import { Button as AntButton, ConfigProvider } from "antd";

function Button({loading, children, type, size, className, ...rest}) {
    return ( 
        <>
         <ConfigProvider theme={{
            components: {
                Button: {
                    defaultHoverBorderColor: "#d55d02",
                    defaultHoverBg: "#d55d02",
                    defaultBg: "#d55d02",
                    defaultHoverColor: "#fff",
                    defaultColor: "#fff",
                    defaultBorderColor: "#d55d02",
                    defaultActiveBorderColor: "#d55d02",
                    defaultActiveBg: "#d55d02",
                    defaultActiveColor: "#FFF",
                    defaultShadow: "0"
                }
            }
        }}>
            <AntButton
            htmlType={type ?? 'button'}
                    {...rest}
                    size={size ?? "large"}
                    loading={loading}
                    className={`rounded-[8px] text-[18px] ${size? "": "h-[45px]"} ${className}`}
                    >
                    {children}
                  </AntButton>
        </ConfigProvider>
        </>
     );
}

export default Button;