import React, { useState } from 'react';
import Modal from 'react-modal';
import './Message.css'; // Import your custom styles
import CoverPreloader from '../preloader/Coverpreloader';
import PassPreloader from '../preloader/PassPreloader';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from '../404/ErrorCatcher';

 const Message =({message,onClose,isOpen})=>{
  const messageTag =
        message !== undefined ? (
          <h3 className={message.toLowerCase().includes('succ') ? 'success' : 'error'}>
            {message}
          </h3>
        ) : (
          <h3>Message could not be opened</h3>
        );


    return (
      <ErrorBoundary>
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Transaction Modal"
      className="transaction-modal"
      overlayClassName="transaction-overlay"
    >
      <div className="modal-content" id='flasher'>
      <span onClick={onClose} className='closex'>x</span>
        
       {messageTag}
        {/* {loading && <PassPreloader loading={loading} isok={false}></PassPreloader>} */}
        <button type='submit' onClick={onClose}>Ok</button>
      </div>
    </Modal>
    </ErrorBoundary>
  );

};

export default  Message