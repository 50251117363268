import './DataCard.css'
import React from 'react'
import { formartToCurrency } from '../../vitals'

export default function DataCard({qty,value,amount,service, name,dname,onSelect}){
    const data ={qty:qty,value:value,amount:amount,service:service, name:name,dname:dname}
    return(
        <div className='container'>
        <div className='datacard'  onClick={() => onSelect(data)}>
        <div className='top-data-card'>
            <div className='row'>
            <div className=' col card-amount'>{formartToCurrency(amount)}</div>
            <div className=' col card-btn'><button type ='button' className='btn btn-primary' 
            onClick={() => onSelect(data)}>Get</button></div>
            </div>
        </div>
        <div className='center-card'>
            <p className='data-amount'>{qty}</p>
        </div>

        <div className='bottom-card'>
            <div className='row'>
            <div className='col'>
                <div className='data-desc'>{dname}</div></div>
            <div className='col '>
                
            <div className='data-network '>{service.charAt(0).toUpperCase() + service.slice(1)} </div></div>
            </div>
        </div>
        </div>

        </div>
    )
}