import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import logo from "../../assets/images/paysit_logo.png";
import "../login/Login.css";
import { Link } from "react-router-dom";
import { registerUser } from "../../vitals";
import CoverPreloader from "../../components/preloader/Coverpreloader";
import Footer from "../dashboard/footer/Footer";
import { validatePhoneNumber } from "../../vitals";
import { useLocation } from "react-router-dom";
import PasswordInput from "../../components/form_inputs/PasswordInput";
import { TbPasswordUser } from "react-icons/tb";
import { Input } from "../../components/form_inputs/Input";
import { useForm } from "react-hook-form";
import { HiOutlineUser } from "react-icons/hi2";
import { TbUserCheck } from "react-icons/tb";
import { MdOutlineMail } from "react-icons/md";
import { LuPhone } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { TbFilterCode } from "react-icons/tb";
import useCustomMessage from "../../components/hooks/useCustomMessage";
import { useRegisterMutation } from "../../APIs/mutation";
import Button from "../../components/form_inputs/Button";

export default function Register() {

    //external hooks
        const { showMessage, contextHolder } = useCustomMessage();

        const registerMutation = useRegisterMutation();

    //


    //react hooks
        const [isLoading, setIsLoading] = useState(false);

    //





  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [ref, setRef] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isregistered, setIsregistered] = useState(false);
  const [userEmail, setuserEmail] = useState("");
  const [phoneError, setPhoneError] = useState("");
  let custUsers = [
    "admin@gmail.com",
    "paysit.info@gmail.com",
    "paysit@gmail.com",
    "test@gmail.com",
  ];

  const location = useLocation();
  const refcode = new URLSearchParams(location.search).get("ref", "");
  useEffect(() => {
    if (refcode !== "") {
      setRef(refcode);
    }
  }, []);
  function handleEmailChange(e) {
    setEmail(e.target.value);
    //console.log(e.target.value);

    if (e.target.value.endsWith("com")) {
      // Make a database check for email
      if (custUsers.includes(e.target.value)) {
        //console.log('User exists');
        if ("vibrate" in navigator) {
          navigator.vibrate([200, 100, 200]);
        }
        setEmail("");
      }
    }
  }
  function handleUserNameChange(e) {
    setUserName(e.target.value);
  }
  function handleFirstnameChange(e) {
    setFirstName(e.target.value);
  }
  function handleLastNameChange(e) {
    setLastName(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }
  function handlePhoneNumberChange(e) {
    const phone = e.target.value;
    setPhoneNumber(phone);
    //setPhoneError('')

    if (phone.length >= 8) {
      let { isValid, network } = validatePhoneNumber(phone);
      //console.log(isValid,network)
      if (isValid) {
      } else {
        if ("vibrate" in navigator) {
          navigator.vibrate([200, 100, 200]);
        }
        //setPhoneNumber('')
        setPhoneError("Invalid Phone number");
      }
    } else if (phone.length === 11 && phone.length >= 13) {
      let { isValid, network } = validatePhoneNumber(phone);
      //console.log(isValid,network)
      if (isValid) {
      } else {
        if ("vibrate" in navigator) {
          navigator.vibrate([200, 100, 200]);
        }
        setPhoneNumber("");
        setPhoneError("Invalid Phone number");
      }
    }
  }
  function handleRefChange(e) {
    setRef(e.target.value);
  }

  async function submitForm(e) {
    setError(null);
    setSuccess(null);
    setLoading(true);
    let user = {
      username: userName,
      password: password,
      email: email,
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      ref: ref,
    };
    e.preventDefault();
    try {
      const response = await registerUser({ user });
      const data = await response.json();
      //console.log(response)
      if (response.ok) {
        console.log(data);
        setSuccess(data["message"]);
        setuserEmail(data["email"]);
        setuserEmail(data["email"]);
        // console.log(data['email'])
        setTimeout(() => {
          setIsregistered(true);
        }, 3000);
      } else if (response.status === 400) {
        if (data.non_field_errors && data.non_field_errors.length > 0) {
          setError(data.non_field_errors.map((error) => error));
        } else {
          throw new Error("Unknown error");
        }
      } else {
        setError(data.message);
      }
    } catch (error) {
      //setError(error)
      // console.log(error)
    } finally {
      setLoading(false);
    }
  }
  // console.log(error);





//new form handling
const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      referral: refcode
    },
  });


  const onSubmit=(data)=>{

    const json = {
        username: data?.username,
        password: data?.password,
        email: data?.email,
        phone_number: data?.phone,
        first_name: data?.first_name,
        last_name: data?.last_name,
        ref: data?.referral,
    }
    
    setIsLoading(true);
    
    registerMutation.mutate({data: json}, {

      onError: (error) => {

        const message = error?.response?.data?.non_field_errors?.join(", ") ?? error?.message

        showMessage("error", message)

        setIsLoading(false);

      },

          onSuccess: (data) => {
            console.log('Mutation successful:', data);

            const res = data?.data



            showMessage("success", res.message)

            setuserEmail(res.email);
            setIsregistered(true);

            setIsLoading(false);

            reset(); //it will reset all values of the inputs

          },
        

    })

    // showMessage("success", data.first_name)
  }






  const handleChange = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };










  return (
    <>
    <div className="register">
      <Header />
      {isregistered ? (
        <div className="verify">
          <div className="verification-container">
            <h2>Verify Your Email Address</h2>
            <p className="desc-txt">
              To start using PaysIt, confirm your Email address with the email
              we sent to:
              <br />
              <b className="support">{userEmail}</b>
            </p>

            <Link to="/login">
              <button className="verifybtn btn text-brand px-5 border-[1.2px] border-block lift me-1">
                {" "}
                Proceed to Login
              </button>
            </Link>
            <p className="support-text">
              Need help? <b className="support">Contact Customer Support </b> @
              <a href="mailto:paysit.info@gmail.com" className="txt-email">
                paysit.info@gmail.com
              </a>
            </p>
          </div>
        </div>
      ) : (
        <section className="w3l-form-36">
          <div className="form-36-mian section-gap">
            <div className="wrapper">
              <div className="form-inner-cont">
                {/* <img src={logo} alt="Logo" className="tw-object-contain" /> */}
                <h3 className="tw-text-center tw-font-bold tw-border-red-50 tw-mt-4">Sign Up</h3>
                <CoverPreloader loading={loading} isok={false} />
                {!loading && error && (
                  <p style={{ marginLeft: "70px", color: "brown" }}>{error}</p>
                )}
                {!loading && success && (
                  <p style={{ marginLeft: "70px", color: "green" }}>
                    {success}
                  </p>
                )}
                <form
                  method="POST"
                  // autoComplete="off"
                  className="signin-form"
                  // onSubmit={submitForm}
                onSubmit={handleSubmit(onSubmit)}
                >
                



                  <div className="">
              <label htmlFor="first_name" className="tw-font-[400] tw-text-black tw-text-[14px] tw-mb-1">
                First Name*
              </label>
              <Input
              addonBefore={<HiOutlineUser size={"3vh"} />}
                name="first_name"
                value={getValues("first_name")}
                placeholder="Enter First Name"
                className={`h-[55px] text-[14px]`}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "First name is required",
                })}
                onChange={(e) => handleChange(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="">
              <label htmlFor="" className="tw-font-[400] tw-text-[14px] tw-mb-1 tw-text-black">
                Last Name*
              </label>
              <Input
              addonBefore={<TbUserCheck size={"3vh"} />}
                name="last_name"
                value={getValues("last_name")}
                placeholder="Enter Last Name"
                className={`h-[55px] text-[14px]`}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => handleChange(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>
 <div className="">
              <label htmlFor="phone" className="tw-font-[400] tw-text-black tw-text-[14px] tw-mb-1">
                Phone Number*
              </label>
              <Input
              addonBefore={<LuPhone size={"3vh"} />}
                name="phone"
                value={getValues("phone")}
                type="number"
                placeholder="Enter Phone number"
                className={`h-[55px] text-[14px]`}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => handleChange(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>


                  <div className="">
                <label htmlFor="email" className="tw-font-[400] tw-text-black tw-text-[14px] tw-mb-1">
                  Email Address*
                </label>
                <Input
                addonBefore={<MdOutlineMail size={"3vh"} />}
                  name="email"
                  value={getValues("email")}
                  type="email"
                  placeholder="Enter Email"
                  size={"large"}
                  className={`text-[16px]`}
                  status={errors.email ? "error" : ""}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                    validate: (value) => !custUsers.includes(value) || "This email is not allowed"
                  })}
                  onChange={(e) => handleChange(e, "email")}
                />
                <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                  {errors.email && errors.email.message}
                </small>
              </div>
              <div className="">
              <label htmlFor="last_name" className="tw-font-[400] tw-text-black tw-text-[14px] tw-mb-1">
                User Name
              </label>
              <Input
              addonBefore={<FaRegCircleUser size={"3vh"} />}
                name="username"
                value={getValues("username")}
                placeholder="Enter User Name"
                className={`h-[55px] text-[14px]`}
                status={errors.username ? "error" : ""}
                {...register("username", {
                  required: "username is required"
                })}
                onChange={(e) => handleChange(e, "username")}
              />
              <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                {errors.username && errors.username.message}
              </small>
            </div><div className="">
              <label htmlFor="last_name" className="tw-font-[400] tw-text-black tw-text-[14px] tw-mb-1">
                Referral (Optional)
              </label>
              <Input
              addonBefore={<TbFilterCode size={"3vh"} />}
                name="referral"
                value={getValues("referral")}
                placeholder="Enter Referreal code if you have..."
                className={`h-[55px] text-[14px]`}
                status={errors.referral ? "error" : ""}
                {...register("referral")}
                onChange={(e) => handleChange(e, "referral")}
              />
              <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                {errors.referral && errors.referral.message}
              </small>
            </div>
              <div className="">
                <label htmlFor="" className="tw-font-[400] tw-text-black tw-text-[14px] tw-mb-1">
                  Password
                </label>
                <PasswordInput
                addonBefore={<TbPasswordUser size={"3vh"} />}
                  name="password"
                  value={getValues("password")}
                  type="password"
                  placeholder="Enter password"
                //   size={"large"}
                  className={`text-[16px]`}
                  status={errors.password ? "error" : ""}
                  {...register("password", {
                    required: "password is required",
                  })}
                  onChange={(e) => handleChange(e, "password")}
                />
                <small style={{ fontSize: "13px" }} className="tw-text-red-500">
                  {errors.password && errors.password.message}
                </small>
              </div>
           


              {/* <div className="form-input">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <input
                      type="text"
                      value={email}
                      name="email"
                      placeholder="Email"
                      required
                      onChange={handleEmailChange}
                    />
                  </div>


                  <div className="form-input">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <input
                      type="text"
                      value={firstName}
                      name="firstname"
                      placeholder="First Name"
                      required
                      onChange={handleFirstnameChange}
                    />
                  </div>
                  <div className="form-input">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <input
                      type="text"
                      name="lastname"
                      value={lastName}
                      placeholder="Last Name"
                      required
                      onChange={handleLastNameChange}
                    />
                  </div>
                  {phoneError && <p className="phoneerror">{phoneError}</p>}
                  <div className="form-input">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <input
                      type="text"
                      name="phonenumber"
                      value={phoneNumber}
                      placeholder="Phone Number"
                      required
                      onChange={handlePhoneNumberChange}
                      onPaste={handlePhoneNumberChange}
                    />
                  </div>

                  <div className="form-input">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <input
                      type="text"
                      name="username"
                      value={userName}
                      placeholder="Choose a Username"
                      required
                      onChange={handleUserNameChange}
                    />
                  </div>
                  <div className="form-input">
                    <span className="fa fa-user" aria-hidden="true"></span>
                    <input
                      type="text"
                      name="ref"
                      placeholder="referal(optional)"
                      value={ref}
                      onChange={handleRefChange}
                    />
                  </div>
                  <div className="form-input">
                    <span className="fa fa-lock" aria-hidden="true"></span>
                    <input
                      type="password"
                      value={password}
                      name="password"
                      minLength="6"
                      maxLength="30"
                      placeholder="Password"
                      required
                      onChange={handlePasswordChange}
                    />
                  </div> */}
                 
                  <div className="login-remember d-grid">
                    <label className="check-remaind">
                      <input type="checkbox" name="remember" />
                      <span className="checkmark"></span>
                      <p className="remember">Remember me</p>
                    </label>

                    {/* <input type="hidden" name="redirect" value="" required />
                                                
                                        <input type="hidden" name="token" value=""/> */}

                    {/* <button
                      name="myButton"
                      className="btn theme-button"
                      type="submit"
                    >
                      Sign Up
                    </button> */}
                    <Button type={"submit"} loading={isLoading}>
                        Sign Up
                    </Button>
                  </div>
                  <div className="new-signup">
                    <a href="/" className="signuplink">
                      Need help?
                    </a>
                  </div>
                </form>

                <div className="social-icons"></div>

                <div className="new-signup text-center">
                  <span style={{ color: "#010020" }}>Already a User?</span>
                  <Link to="/login" className="signuplink">
                    <button className="btn theme-btn"> Sign in</button>
                  </Link>
                </div>
              </div>

              <div className="copy-right" style={{ marginLeft: "25px" }}>
                <p>Express VTU Services | PaysIt</p>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer></Footer>
    </div>
{contextHolder}

    </>
  );
}
