
import React,{useEffect, useState,}from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Preloader from '../../components/preloader/Preloader'
import './MobileApp.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown} from '@fortawesome/free-solid-svg-icons';

import { fetchApps,data_url ,postApps} from "../../vitals";
import { Link } from "react-router-dom";
import axios from 'axios';
import { saveAs } from 'file-saver';


function MobileApp(){
    const [loading,setLoading] = useState(false)
    const [appData,setapData] = useState('')
    const [progressval,updateProgress] = useState(0)
    const [fullLenght,setfullLenght] = useState(0)
    const downloadFile = async (platform) => {
        let androidUrl = appData['android_apk'];
        let iosUrl = appData['ios_apk'];
        let uri = platform === 'android' ? androidUrl : iosUrl;
    
        if (uri == null || uri.includes('ios')) {
            alert('Error in iOS versioning for your device');
            return;
        }
    
        let sdk = platform === 'android' ? 'paysit.apk' : 'paysit.ipa';
    
        try {
            const response = await fetch(uri);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = contentDisposition ? contentDisposition.split('filename=')[1] : sdk;
    
            const reader = response.body.getReader();
            const contentLength = +response.headers.get('Content-Length');
    
            let receivedLength = 0;
            const chunks = [];
    
            const progressBar = document.getElementById('progress-bar');
            progressBar.value = 0;
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
    
                chunks.push(value);
                receivedLength += value.length;
    
                // Update progress bar
                progressBar.value = (receivedLength / contentLength) * 100;
                updateProgress(receivedLength)
                setfullLenght(contentLength)
                console.log(`Received ${receivedLength} of ${contentLength}`);
                if(+receivedLength==+contentLength){
                    setTimeout(()=>{
                        updateProgress(0)

                    },2000)
                }
            }
    
            const blob = new Blob(chunks);
            saveAs(blob, sdk);
            postApps();
    
        } catch (e) {
            console.log('error', e);
        }
    };
    
    useEffect(()=>{
        setLoading(true)
        async function fetchappData(){
            const res =  await fetchApps()
        const data = await res.json();
            //console.log(data)
        setapData(data['data'])
        setLoading(false)
        }

        fetchappData()
    },[])

    return (<>
    <Header/>
    
    <br/>
    <section className="mt-3 md:mt-20 mx-3 md:mx-24 dex-class">
        <h1 className="mb-3 md:mb-10 text-base md:text-xl text-brand font-bold">
            How It Works
        </h1>

        <div className="gap-5">
            <div className="col px-10 bg-brand-light">
                <div className="mb-2 mt-4 bold-brand">
                Download  Mobile App

                </div>
                <h3 className="text-base font-bold text-brand mb-2">
                {appData? appData['name']:''}
                </h3>
                <p className="text-black mb-2 md:mb-4">
                Download PaysIt app for seamless access to all the features of our website. Enjoy easy navigation, real-time updates, personalized content, secure transactions, and enhanced user experience anytime, anywhere!                </p>
            </div>
        </div>
        <Preloader loading={loading}/>
        {progressval>1? <progress id="progress-bar" value='0' max='100'
        style={{width:'100%'}}
        />:<progress id="progress-bar" value='0' max='100'
        style={{width:'100%',display:'none'}}
        />}

        </section>
        <div className="devices">
            <div className="container" style={{marginTop:'20px',marginBottom:'200px'}}>
            <div className="row">
            <div className="col" >
            <img src="https://www.vectorlogo.zone/logos/android/android-icon.svg" alt="android logo" style={{marginLeft:'30px'}}></img>
            {appData? <button className="download-android" onClick={()=>downloadFile('android')}  >
            <FontAwesomeIcon icon={faArrowDown}  style={{color:'white'}}/> Download Android
            </button>:''}
            </div>
            <div className="col">
            <img src="https://www.vectorlogo.zone/logos/apple/apple-icon.svg" alt="Ios logo" style={{marginLeft:'30px'}}></img>
            {appData?<button className="download-ios" onClick={() => downloadFile('ios')}>
            <FontAwesomeIcon icon={faArrowDown}  style={{color:'white'}}/> Download For IOS
            </button>:''}
            </div>
            </div>
            </div>
        
        </div>

      <Footer/>
        

           
        </>)
}


export default MobileApp
