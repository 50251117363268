import React ,{useContext,useState} from "react"
import './Servicecard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv,faArrowsSpin,faBarcode,faPlugCirclePlus,faMobileScreenButton, faUser,faBank,faCopy} from '@fortawesome/free-solid-svg-icons';
import dataplug from '../../../assets/images/dataplug.png'
import {userProfileContext } from "../../../components/userprofilecontext/UserContext";
import CoverPreloader from "../../../components/preloader/Coverpreloader";
import { Link } from "react-router-dom";
import { formartToCurrency } from "../../../vitals";


function Servicecard({account}){
    const {loading,user} = useContext(userProfileContext)
    return(
        <div className=" row serviceblock">
            <div className="col">
            <div className="card">
                <div className="contents">
                    <div className="row">
                        <div className="col">
                        <div className="top-label">Quick Services</div>
                        </div>
                        <div className="col">
                            <div className="top-wallet">Wallet Balance  &nbsp;
                            <b style={{fontWeight:'900',fontSize:'16px'}}>{formartToCurrency(user.wallet_balance)}</b></div>
                            
                        </div>
                    </div>
            
                <div className="row main-ft">
                <Link to ="/dashboard/data/data?service=data" className="custom-link"><div className="service">
                    <span className="service-icon"><FontAwesomeIcon icon={faArrowsSpin} />
                    <br/><i className="service-name">Data</i></span>
                    </div></Link>
                    
                    <Link to='/dashboard/tv/service' className="custom-link"><div className="service">
                   <span className="service-icon"><FontAwesomeIcon icon={faTv} />
                    <br/><i className="service-name">TV SUBs</i></span>
                    </div></Link>

                    <Link to ="/dashboard/epins/" className="custom-link "> 
                    <div className="service">
                    <span className="service-icon"><FontAwesomeIcon icon={faBarcode} />
                    <br/><i className="service-name">E-Pins/Cards</i></span>
                    </div></Link>
                    
                    <Link to ="/dashboard/electricity/bills/" className="custom-link "> 
                    <div className="service">
                    <span className="service-icon"><FontAwesomeIcon icon={faPlugCirclePlus} />
                    <br/><i className="service-name">Electricity</i></span>
                    </div></Link>
                    <Link to ="/dashboard/data/data?service=airtime" className="custom-link "> 
                    <div className="service">
                    <span className="service-icon"><FontAwesomeIcon icon={faMobileScreenButton} />
                    <br/><i className="service-name">Airtime</i></span>
                    </div></Link>
                    <Link to ="/dashboard/user/account/" className="custom-link "> 
                    <div className="service">
                    <span className="service-icon"><FontAwesomeIcon icon={faUser} />
                    <br/><i className="service-name">Ref.& Earn</i></span>
                    </div></Link>
                
                </div>
                </div>
            </div>
            </div>
            <div className="col">
                <div className="card2contents">
            <div className="card2 " >
                    
                <img src={dataplug} alt="dataphoto"/>


                </div>
            </div>
            </div>
    
    {loading &&<CoverPreloader loading={loading} isok={false}/>}

    </div>
    )
}


export function AccountCard(){
    const {loading,user} = useContext(userProfileContext)
    const [isCopied, setIsCopied] = useState(false);
  const copyThisText = (text) => {
    try {
      navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (err) {
      console.error('Unable to copy text', err);
    } finally {
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

    return(
        <div className="vba">
        {user.banks && user.banks[0].length>0 ? <div className="depositcard" onClick={()=>{
                    copyThisText(user.banks[0][0].account_number)
                  }}>
          <div className='banksvg'>
            <FontAwesomeIcon icon={faBank} />
          </div>
          <p className='bank-name'>Bank Name</p>                 

          <p className='bank' style={{color:'brown'}}>{user.banks[0].length>0 ? <b>{user.banks[0][0].bank_name}</b>:''}</p>
          <div className='card-foot'>
            <p>Account Number</p>
            <p className='account-no' style={{color:'brown'}}>{user.banks[0].length>0 ? <b style={{fontWeight:'900'}}>{user.banks[0][0].account_number}</b>:''}</p>
            <div>
              {isCopied ? (
                <>
                  <FontAwesomeIcon icon={faCopy} />Copied!
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCopy} onClick={()=>{
                    copyThisText(user.banks[0][0].account_number)
                  }} />Copy
                </>
              )}
            </div>

          </div>
              <small style={{marginLeft:'60%'}}>{formartToCurrency(50)} charges apply</small>
        </div>
        :''}
      </div>
    )
}



export function PaysItCard({user}){
const [isCopied, setIsCopied] = useState(false);



  

const copyThisText = (text) => {
  try {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  } catch (err) {
    console.error('Unable to copy text', err);
  } finally {
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }
};

  return( <div className="contents">
    {
      user.account? <><div className="vba">
      <div className="card" >
                <h3>Balance/Profit View Card</h3>
        <div className="row">

          <div className="col">
            
       <div className='banksvg'>
         <FontAwesomeIcon icon={faBank} />
       </div>
       <p className='bank-name'>Bank Name</p>                 

       <p className='bank' style={{color:'brown'}}>Data: <b>{user['account'].topup_data_bank_name}</b></p>
       <p className='bank' style={{color:'brown'}}>Airtime/Tv :<b>{user['account'].topup_airtime_bank_name}</b></p>
       
      
          </div>
          <div className="col">
          <div className="top-wallet" style={{color:'brown',fontWeight:'700'}}>PaysIt Data Balance  &nbsp;
          <b style={{fontWeight:'800',fontSize:'16px', color:'black'}}>{formartToCurrency(user.account.data_balance)}</b></div>
          <br/>
          <div className="top-wallet" style={{color:'brown',fontWeight:'700'}}>PaysIt Airtime Balance  &nbsp;
          <b style={{fontWeight:'800',fontSize:'16px', color:'black'}}>{formartToCurrency(user.account.airtime_balance)}</b></div>
          <br/>
          <div className="top-wallet" style={{color:'green',fontWeight:'700'}}>Sale Profit  &nbsp;
          <b style={{fontWeight:'900',fontSize:'18px', color:'blue'}}>{formartToCurrency(user.account.profit)}</b></div>
      
        </div>
        </div>
       <div className='card-foot'>
         <p>Account Numbers</p>
         <p className='account-no' style={{color:'brown'}}>Data: <b style={{fontWeight:'900'}}> {user.account.topup_data_acc_number}</b></p>
         <div>
           {isCopied ? (
             <>
               <FontAwesomeIcon icon={faCopy} />Copied!
             </>
           ) : (
             <>
               <FontAwesomeIcon icon={faCopy} onClick={()=>{
                 copyThisText(user.account.topup_data_acc_number)
               }} />Copy
             </>
           )}
         </div>

         <p className='account-no' style={{color:'brown'}}> Aitime/Tv :<b style={{fontWeight:'900'}}> {user.account.topup_airtime_acc_number}</b></p>
         <div>
           {isCopied ? (
             <>
               <FontAwesomeIcon icon={faCopy} />Copied!
             </>
           ) : (
             <>
               <FontAwesomeIcon icon={faCopy} onClick={()=>{
                 copyThisText(user.account.topup_airtime_acc_number)
               }} />Copy
             </>
           )}
         </div>

       </div>

       <small style={{marginLeft:''}}>Data : <b style={{color:'brown'}}>{user.account.topup_data_acc_name}</b></small>
       <small style={{marginLeft:''}}>Airtime/Tv : <b style={{color:'brown'}}>{user.account.topup_airtime_acc_name}</b></small>
       </div>
   </div></>:''
    }</ div>
      
  )
}

export default Servicecard
