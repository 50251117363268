import React, { useState } from "react";
import Modal from 'react-modal';
import './ID.css'
import { CreateVirtualAccount } from "../../vitals";
import { useNavigate } from "react-router-dom";
import { UserProfileProvider } from "../userprofilecontext/UserContext";
import CoverPreloader from "../preloader/Coverpreloader";
import Message from "../message/Message";
export default function ID({userdata,onClose,isOpen}){
    const [id,setId] = useState('')
    const [id_no,setId_no] = useState('')
    const [loading,setLoading] = useState(false)
    const [message,setMessage] = useState('')
    const [messageOpen,setMessageOpen] = useState(false)
    const navigateto = useNavigate()
    function changeId(e){
        setId(e.target.value)
    }

    function changeId_no(e){
        setId_no(e.target.value)
    }
    function submitForm(e){
        e.preventDefault()
        setLoading(true)
        async function submitID(){
            try{
                const response = await CreateVirtualAccount({navigateto,id,id_no})
                const data = await response.json()
                if(data['message']){setMessage(data['message'])
                setMessageOpen(true)
                }else{
                    onClose()
                }
            }catch(e){
                console.log(e)
            }finally{
                setLoading(false)
            }
        }
    
        submitID()
       
    }
    function closeMessage(){
        //onclose()
        setMessageOpen(false)
        onClose()
        window.location.reload()
    }
    return (
        <UserProfileProvider>
        <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        //contentLabel="Transaction Modal"
        className=""
        overlayClassName=""
      >
    <div className="">
<div className="modal-header">
<h5 className="modal-title" id="exampleModalLabel">User KYC Update </h5>
<span className="close" data-dismiss="alert" aria-label="Close">
<span onClick={onClose} style={{color:'brown'}}>x</span>
</span>
</div>
<form className="justify-content-center text-center updatecard"  onSubmit={submitForm}>
<div className=" alert-warning  fade show" role="alert">
Dear <strong> {userdata.user.first_name} {userdata.user.last_name}</strong>, 
<p> Please be informed that all <strong>virtual accounts</strong> not linked with a Bank Verification Number (BVN) or National Identification Number (NIN) of the beneficiary will be deactivated by<strong> March, 2024 </strong> and payments to such accounts would be rejected.
</p><br/>
<p>Kindly update/add your BVN or NIN for transaction on your dedicated accounts.</p>
<code><br/>
<hr/>
<font color="red"> <strong>Disclaimer:</strong> This is for verification purpose only as required by Central Bank of Nigeria (CBN) for the issuance and management of virtual accounts. PaysIt NG will never store your BVN or NIN in any means. </font>
</code>

</div>
<div className="row justify-content-center">
    <br/>
    <h4>Use one of BVN or NIN</h4>
<div className="col-12">
    <select name="id" id="" style={{color:'black'}}  onChange={changeId} className="form-control">
    <option value="0">Select ID</option>
    <option value="bvn">BVN</option>
    <option value="nin">NIN</option>
    </select>
</div><br/>
{loading? <CoverPreloader loading={loading}/>:''}
<div className="col-12">
<input type="number" id="idno" name="id_no" value={id_no} onChange={changeId_no} className="form-control" min={11} placeholder="Enter Your BVN or NIN" required />
</div>
</div>
<div className="text-center">
<button  id="btnelectric" className ="btn btn-rounded btn-primary text-center"  type="submit">
    <i className ="fa fa-user"></i>Update </button>

</div>
</form>

    </div>
    <Message message={message} isOpen={messageOpen} onClose={closeMessage}/>
        </Modal>
        </UserProfileProvider>
    )
}